@.mapSiteHandler = (zoom, markers) ->
    map = undefined
    infoWindow = undefined

    google.maps.event.addDomListener(window, 'load', ->
        initialize()
    )

    initialize = (->
        mapOptions =
            center: new (google.maps.LatLng)(52.068678, 19.479808)
            zoom: zoom
            mapTypeId: 'roadmap'
        map = new (google.maps.Map)(document.getElementById('map'), mapOptions)
        # Tworzenie infoWindow
        infoWindow = new (google.maps.InfoWindow)
        # Zamykanie infoWindow po kliknieciu na mape
        google.maps.event.addListener(map, 'click', ->
            infoWindow.close()
            return
        )
        # Wyswietlanie markerow
        displayMarkers()
        return
    )

    # Wyswietlanie markerow
    displayMarkers = (->
        # wyznaczanie granicy mapy na podstawie markerow
        bounds = new (google.maps.LatLngBounds)
        # przejscie po tablicy i tworzenie markera
        i = 0
        len = markers.length
        while i < len
            latlng = new (google.maps.LatLng)(markers[i].lat, markers[i].lng)
            name = markers[i].name
            icon = markers[i].icon
            text = markers[i].text
            uri = markers[i].uri
            createMarker(latlng, name, icon, text, uri)
            # dodanie pozycji markera do tworzenia granic
            bounds.extend(latlng)
            i++

        #dopasowanie granic do mapy
        #map.fitBounds(bounds);
        map.setCenter(bounds.getCenter())
        return
    )

    # Rysowanie markera
    createMarker = ((latlng, name, icon, text, uri) ->
        marker = new google.maps.Marker({
            map: map
            position: latlng
            title: name
            icon: icon
        })
        #sprawdzenie czy istnieje url
        uri = if !!uri then '<a href="' + uri + '" target="_blank">Pokaż szczegóły >></a></div>' else ''
        # event odpowiadajacy za wyswietlanie infowindow po kliknieciu na marker
        google.maps.event.addListener(marker, 'click', ->
            # tworzenie zawartosci infowindow
            iwContent = '<div class="infoWindow">' + '<div class="infoWindow__title">' + name + '</div>' + '<div class="infoWindow__text">' + text + '</div>' + '<div class="infoWindow__redirect">' + uri + '</div>' + '</div>'
            # dodanie zawartosci do infoWindow
            infoWindow.setContent(iwContent)
            # otworzenie infowindow dla konkretnego markera
            infoWindow.open(map, marker)
            map.panTo(infoWindow.position)
            return
        )
        #style do infoWindow
        google.maps.event.addListener(infoWindow, 'domready', ->
            $('.gm-style-iw').next().css(display: 'none')

            iwBackground = $('.gm-style-iw').prev()
            iwBackground.children(':nth-child(2)').css(display: 'none')
            iwBackground.children(':nth-child(4)').css(display: 'none')

            return
        )
        return
    )
